import { useMemo, MouseEvent } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Dayjs } from 'dayjs';
import useTranslation from 'next-translate/useTranslation';
import { DayPickerBase } from 'react-day-picker';
import { ReactComponent as SwitchIcon } from '@ui/assets/icons/arrows.svg';
import Autocomplete, {
  StyledInput,
} from '@ui/components/Autocomplete/Autocomplete';
import { Item } from '@ui/components/Autocomplete/AutocompleteTypes';
import Button from '@ui/components/Button/Button';
import DatePickerContainer from '@ui/components/DatePicker/DatePickerContainer';
import { GridColumn, GridWrap } from '@ui/components/Grid/Grid';
import { InputContainer } from '@ui/components/Input/InputContainer';
import { BaseReadonlyInput } from '@ui/components/Input/ReadonlyInput';
import PassengerCounterInput from '@ui/components/PassengerCounter/PassengerCounterInput';
import { PassengerTypeProp } from '@ui/components/PassengerCounter/passengerTypes';
import { PopupContainer } from '@ui/components/Popup/Popup';
import { mqMax, mqMin, loadingAnimation } from '@ui/styles/base';
import { useConstants } from '@web/context/ConstantContext';
import useGetIcon from '@web/context/hooks/useGetIcon';
import useSearchConfig from '../hooks/useSearchConfig';
import { StyledGridRow } from './SearchWidgetStyledComponents';
import SearchWidgetTransportIcon from './SearchWidgetTransportIcon';

export interface Props {
  addPassenger: (paxType: string) => void;
  className?: string;
  datePickerErrorMessage?: string;
  departingDatePlaceholder?: string;
  departureDate: Maybe<Dayjs>;
  destinationAutocompleteItems: Item[];
  destinationErrorMessage?: string;
  disabledDays?: (day: Dayjs) => boolean;
  disclaimer?: string;
  enableRoundTrip?: boolean;
  inTransition?: boolean;
  isDestinationAutocompleteLoading?: boolean;
  isOneWay?: boolean;
  isOriginAutocompleteLoading?: boolean;
  onSearchClick: () => void;
  onSelectDestinationItem?: (item: Item) => void;
  onSelectOriginItem?: (item: Item) => void;
  onSelectedDateRange: (dateRange: DateRange) => void;
  onShowDatePickerModal: (s: boolean) => void;
  onShowDatePickerPopup: (s: boolean) => void;
  onSwitchClick: () => void;
  onToggleOneWay: () => void;
  originAutocompleteItems: Item[];
  originErrorMessage?: string;
  passengerTypes: PassengerTypeProp[];
  removePassenger: (paxType: string) => void;
  returnDate: Maybe<Dayjs>;
  selectedDestinationItem?: Item;
  selectedOriginItem?: Item;
  showDatePickerModal: boolean;
  showDatePickerPopup: boolean;
  showTitleOnTablets?: Maybe<boolean>;
  updateAge: (updateIndex: number, newAge: number, paxType: string) => void;
  weekStartsOn?: DayPickerBase['weekStartsOn'];
}

export const StyledButton = styled(Button)<{
  extraSpaceAbove?: Maybe<boolean>;
  isLoading?: boolean;
}>(({ extraSpaceAbove, isLoading, theme: { colours, shape } }) => [
  css`
    height: 54px;
    border-radius: ${shape.buttonPrimaryBorderRadius}px;
    background: ${colours.components.search.interactive.default};
    color: ${colours.components.search.text.on.interactive};

    &:hover {
      background: ${colours.components.search.interactive.hover};
    }

    &:active {
      background: ${colours.components.search.interactive.pressed};
    }

    ${mqMin.Small} {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  `,
  extraSpaceAbove &&
    css`
      ${mqMin.Medium} {
        margin-top: 21px;
      }
    `,
  isLoading &&
    loadingAnimation(16, colours.components.search.text.on.interactive),
]);

export const StyledGridWrap = styled(GridWrap)<{
  showTitleOnTablets?: Maybe<boolean>;
}>(({ showTitleOnTablets, theme: { spacings } }) => [
  css`
    height: 100%;
  `,
  showTitleOnTablets &&
    css`
      ${mqMin.Medium} {
        height: 100px;
      }

      ${StyledGridRow} {
        ${mqMin.Small} {
          flex-direction: column;
          padding: ${spacings['24']}px;
          gap: ${spacings['24']}px;
        }

        ${mqMin.Medium} {
          flex-direction: row;
          padding: 0 ${spacings['24']}px;
          gap: ${spacings['8']}px;
        }
      }
    `,
]);

export const Container = styled.div<{
  showTitleOnTablets?: Maybe<boolean>;
}>(({ showTitleOnTablets, theme: { colours } }) => [
  css`
    display: flex;
    width: 100%;
    justify-content: center;

    ${mqMin.Small} {
      height: 100px;
      background: ${colours.components.search.surface.default};
      color: ${colours.text.default};
    }
  `,
  showTitleOnTablets &&
    css`
      ${mqMin.Small} {
        height: 100%;
      }

      ${mqMin.Medium} {
        height: 100px;
      }
    `,
]);

export const StyledDatePickerContainer = styled(DatePickerContainer)<{
  showTitleOnTablets?: Maybe<boolean>;
}>(({ showTitleOnTablets }) => [
  css`
    ${InputContainer} {
      ${mqMin.Small} {
        border-radius: unset;
        border-right: none;
      }
    }
  `,
  showTitleOnTablets &&
    css`
      ${PopupContainer} {
        ${mqMax.Medium} {
          left: 0;
          transform: none;
        }
      }
    `,
]);

export const StyledPassengerCounterInput = styled(PassengerCounterInput)(
  () => css`
    ${BaseReadonlyInput} {
      ${mqMin.Small} {
        border-radius: unset;
      }
    }
  `,
);

export const StyledAutocomplete = styled(Autocomplete)(
  ({ theme: { colours } }) => css`
    ${StyledInput} {
      ${mqMin.Small} {
        border-radius: unset;
        border-right: none;
        border-left: none;

        &:focus-within {
          border: 2px solid ${colours.brand.primary};
        }
      }
    }
  `,
);

export const StyledFirstAutocomplete = styled(Autocomplete)(
  ({ theme: { colours, shape } }) => css`
    ${StyledInput} {
      ${mqMin.Small} {
        border-radius: unset;
        border-bottom-left-radius: ${shape.searchWidgetBorderRadius}px;
        border-top-left-radius: ${shape.searchWidgetBorderRadius}px;

        &:focus-within {
          border: 2px solid ${colours.brand.primary};
          border-bottom-left-radius: unset;
        }
      }
    }
  `,
);

const StyledLabel = styled.span`
  display: none;
  ${mqMin.Medium} {
    display: block;
    font-size: 14px;
  }
`;

const StyledSwitchIcon = styled(SwitchIcon)(
  ({ theme }) => css`
    width: 17px;
    height: 12px;
    cursor: pointer;
    fill: ${theme.colours.icons.default};
  `,
);

const SearchWidget = ({
  addPassenger,
  className,
  datePickerErrorMessage,
  departureDate,
  destinationAutocompleteItems,
  destinationErrorMessage,
  disabledDays,
  enableRoundTrip,
  inTransition,
  isDestinationAutocompleteLoading,
  isOneWay = false,
  isOriginAutocompleteLoading,
  onSearchClick,
  onSelectDestinationItem,
  onSelectedDateRange,
  onSelectOriginItem,
  onShowDatePickerModal,
  onShowDatePickerPopup,
  onSwitchClick,
  onToggleOneWay,
  originAutocompleteItems,
  originErrorMessage,
  passengerTypes,
  removePassenger,
  returnDate,
  selectedDestinationItem,
  selectedOriginItem,
  showDatePickerModal,
  showDatePickerPopup,
  showTitleOnTablets,
  updateAge,
}: Props) => {
  const { t } = useTranslation();
  const { locale } = useConstants();
  const selectedDateRange = useMemo(
    () => ({
      from: departureDate,
      to: returnDate,
    }),
    [departureDate, returnDate],
  );

  const trainAndPlaneIcon = useGetIcon()('trainAndPlaneIcon');
  const trainIcon = useGetIcon()('trainIcon');

  const { showInputLabels, weekStartsOn } = useSearchConfig();

  return (
    <Container className={className} showTitleOnTablets={showTitleOnTablets}>
      <StyledGridWrap showTitleOnTablets={showTitleOnTablets}>
        <StyledGridRow
          align={['center']}
          direction="row"
          justify={['center']}
          wrap={['wrap', 'wrap', 'wrap', 'nowrap']}
        >
          <GridColumn px={0} width={[12]}>
            {showInputLabels && <StyledLabel>{t('From')}</StyledLabel>}
            <StyledFirstAutocomplete
              errorMessage={originErrorMessage}
              id="originAutocomplete"
              isLoading={isOriginAutocompleteLoading}
              items={originAutocompleteItems}
              leftIcon={
                <SearchWidgetTransportIcon
                  airportAndTrainIcon={trainAndPlaneIcon}
                  trainIcon={trainIcon}
                  transportType={selectedOriginItem?.transportType}
                />
              }
              noMatchesMessage={t('No results found.')}
              onSelect={onSelectOriginItem}
              placeholder={t('From')}
              rightIcon={
                <StyledSwitchIcon
                  onClick={(e: MouseEvent<SVGElement>) => {
                    e.stopPropagation();
                    onSwitchClick();
                  }}
                />
              }
              selectedItem={selectedOriginItem}
            />
          </GridColumn>
          <GridColumn px={0} width={[12]}>
            {showInputLabels && <StyledLabel>{t('To')}</StyledLabel>}
            <StyledAutocomplete
              errorMessage={destinationErrorMessage}
              id="destinationAutocomplete"
              isLoading={isDestinationAutocompleteLoading}
              items={destinationAutocompleteItems}
              leftIcon={
                <SearchWidgetTransportIcon
                  airportAndTrainIcon={trainAndPlaneIcon}
                  trainIcon={trainIcon}
                  transportType={selectedDestinationItem?.transportType}
                />
              }
              noMatchesMessage={t('No results found.')}
              onSelect={onSelectDestinationItem}
              placeholder={t('To')}
              selectedItem={selectedDestinationItem}
            />
          </GridColumn>
          <GridColumn px={0} width={[12]}>
            {showInputLabels && <StyledLabel>{t('Date')}</StyledLabel>}
            <StyledDatePickerContainer
              align="middle"
              ariaLabel={t('Date')}
              dateRange={selectedDateRange}
              disabledDays={disabledDays}
              enableRoundTrip={enableRoundTrip}
              errorMessage={datePickerErrorMessage}
              isOneWay={isOneWay}
              locale={locale}
              onShowDatePickerModal={onShowDatePickerModal}
              onShowDatePickerPopup={onShowDatePickerPopup}
              setDateRange={onSelectedDateRange}
              showDatePickerModal={showDatePickerModal}
              showDatePickerPopup={showDatePickerPopup}
              showTitleOnTablets={showTitleOnTablets}
              toggleIsOneWay={onToggleOneWay}
              weekStartsOn={weekStartsOn}
            />
          </GridColumn>
          <GridColumn px={0} width={[12]}>
            {showInputLabels && <StyledLabel>{t('Passengers')}</StyledLabel>}
            <StyledPassengerCounterInput
              addPassenger={addPassenger}
              ariaLabel={t('Number of passengers')}
              passengerTypes={passengerTypes}
              removePassenger={removePassenger}
              updateAge={updateAge}
            />
          </GridColumn>
          <GridColumn px={0} width={[12]}>
            <StyledButton
              dataCy="search-widget-submit"
              extraSpaceAbove={showInputLabels}
              isLoading={inTransition}
              onClick={onSearchClick}
            >
              {t('Search')}
            </StyledButton>
          </GridColumn>
        </StyledGridRow>
      </StyledGridWrap>
    </Container>
  );
};

export default SearchWidget;
